// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductEditor-module__addProductButtons--bsm4wpvb{margin-bottom:16px;display:flex;gap:8px}.ProductEditor-module__product--B4dh9\\+kD{display:flex;justify-content:space-between;gap:20px}.ProductEditor-module__buttons--sWnvcdmC{display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/pages/ProductEditor/ProductEditor.module.scss"],"names":[],"mappings":"AAAA,mDACE,kBAAA,CACA,YAAA,CACA,OAAA,CAEF,0CACE,YAAA,CACA,6BAAA,CACA,QAAA,CAGF,yCACE,YAAA,CACA,OAAA","sourcesContent":[".addProductButtons {\n  margin-bottom: 16px;\n  display: flex;\n  gap: 8px;\n}\n.product {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n}\n\n.buttons {\n  display: flex;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addProductButtons": "ProductEditor-module__addProductButtons--bsm4wpvb",
	"product": "ProductEditor-module__product--B4dh9+kD",
	"buttons": "ProductEditor-module__buttons--sWnvcdmC"
};
export default ___CSS_LOADER_EXPORT___;
