import { Button, Card, Empty, Space, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';

import { useAppSelector } from '@/shared/hooks';
import {
  billingModel,
  serviceIsVisible,
  useCancelSubscriptionModal,
  useRemoveSubscriptionModal,
} from '@/entities/billing';
import { SubscriptionSummary } from '@/features/subscriptionSummary';
import { PATHS } from '@/shared/config';
import { PackageBar } from '@/features/packageBar';

const { Title } = Typography;

type CurrentSubscriptionProps = {
  organizationID: string;
  userID: string;
};

const selectAccountInfoLoadingState =
  billingModel.selectors.getLoadingStateSelector('accountInfo');

// TODO: Remove Number constructor when in protocol fix bigint
export const CurrentSubscription = (props: CurrentSubscriptionProps) => {
  const { organizationID, userID } = props;

  const { ID: accountID } = useAppSelector(
    billingModel.selectors.selectAccountInfo,
  );
  const currentSubscription = useAppSelector(
    billingModel.selectors.selectCurrentSubscription,
  );
  const accountInfoLoadingState = useAppSelector(selectAccountInfoLoadingState);

  const openCancelSubscriptionModal = useCancelSubscriptionModal();
  const openRemoveSubscriptionModal = useRemoveSubscriptionModal();

  const isCurrentSubscription = Boolean(currentSubscription);

  return (
    <Card
      style={{ height: '100%' }}
      title={
        <Title level={3} style={{ marginBottom: 0 }}>
          <FormattedMessage
            id="currentSubscription.title"
            defaultMessage="Current subscription"
          />
        </Title>
      }
      bordered={false}
      loading={accountInfoLoadingState !== 'succeeded'}
      actions={
        isCurrentSubscription
          ? [
              <Button
                key="remove"
                type="primary"
                onClick={() =>
                  openRemoveSubscriptionModal({
                    subscriptionID: currentSubscription.ID,
                    accountID,
                  })
                }
              >
                <FormattedMessage
                  id="currentSubscription.removeSubscription"
                  defaultMessage="Remove"
                />
              </Button>,
              <Button
                key="cancel"
                disabled={!currentSubscription?.AutoRenewal}
                type="text"
                onClick={() =>
                  openCancelSubscriptionModal({
                    subscriptionID: currentSubscription.ID,
                    accountID,
                  })
                }
              >
                <FormattedMessage
                  id="currentSubscription.cancelSubscription"
                  defaultMessage="Cancel"
                />
              </Button>,
            ]
          : null
      }
      extra={
        !currentSubscription ? (
          <Link to={generatePath(PATHS.addSubscription, { organizationID, userID })}>
            <FormattedMessage
              id="currentSubscription.add"
              defaultMessage="Add"
            />
          </Link>
        ) : null
      }
    >
      {isCurrentSubscription ? (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <SubscriptionSummary
            productID={currentSubscription?.ID}
            title={currentSubscription?.Model?.Name}
            currency={currentSubscription?.Model?.Currency}
            price={Number(currentSubscription?.Model?.Price ?? 0)}
            startDate={Number(currentSubscription.Start.seconds) * 1000}
            endDate={Number(currentSubscription.End.seconds) * 1000}
            status={currentSubscription.Status}
            isAutoRenewal={currentSubscription.AutoRenewal}
          />

          {currentSubscription.Services.filter(serviceIsVisible).map(
            (packageItem) => (
              // need uniq key
              <PackageBar
                key={`${packageItem.Kind.case}-${packageItem.Group}-${packageItem.Types.toString()}`}
                packageItem={packageItem}
              />
            ),
          )}
        </Space>
      ) : (
        <Empty
          description={
            <FormattedMessage
              id="currentSubscription.noSubscription"
              defaultMessage="No subscription"
            />
          }
        />
      )}
    </Card>
  );
};
