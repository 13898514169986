import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/model/store';
import { LoadingStateType } from '@/shared/config';
import { Account } from 'protocol/api/billing_new/dto_account_new_pb';
import { Order } from 'protocol/api/billing_new/dto_order_new_pb';
import { ProductStateStatus } from 'protocol/api/billing_new/dto_product_new_pb';

import { BillingStateLoadersKeys } from './billingSlice';

export const selectInventory = (state: RootState) => state.billing.inventory;

const selectAvailableProducts = (state: RootState) => state.billing.products;

export const selectAccountInfo = (state: RootState): Account =>
  state.billing.accountInfo;

export const selectOrders = (state: RootState): Order[] => state.billing.orders;

export const selectCurrentSubscription = createSelector(
  selectInventory,
  (inventory) =>
    inventory.find(
      (inventoryItem) =>
        inventoryItem.Model.Kind.case === 'Subscription' &&
        (inventoryItem.Status === ProductStateStatus.ProductStateStatusActive ||
          inventoryItem.Status ===
            ProductStateStatus.ProductStateStatusAwaitingActivation),
    ),
);

export const selectPackages = createSelector(selectInventory, (inventory) =>
  inventory.filter(
    (inventoryItem) =>
      inventoryItem.Status !== ProductStateStatus.ProductStateStatusDeleted &&
      (inventoryItem.Model.Kind.case === 'Package' ||
        inventoryItem.Model.Kind.case === 'Addon'),
  ),
);

export const selectAddOns = createSelector(selectInventory, (inventory) =>
  inventory.filter(
    (inventoryItem) => inventoryItem.Model.Kind.case === 'Addon',
  ),
);

export const getLoadingStateSelector =
  (loadingKey: BillingStateLoadersKeys) =>
  (state: RootState): LoadingStateType =>
    state.billing.loaders[loadingKey];

export const selectAvailableSubscriptions = createSelector(
  selectAvailableProducts,
  (products) =>
    products.filter((product) => product.Kind.case === 'Subscription'),
);

export const selectAvailablePackages = createSelector(
  selectAvailableProducts,
  (products) => products.filter((product) => product.Kind.case === 'Package'),
);

export const selectAvailableAddons = createSelector(
  selectAvailableProducts,
  (products) => products.filter((product) => product.Kind.case === 'Addon'),
);

export const selectSubscriptionToAdd = (state: RootState) =>
  state.billing.effectToAdd;
