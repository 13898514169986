import { ProductModel } from 'protocol/api/billing_new/dto_product_new_pb';
import {
  ServiceGroupName,
  ServiceType,
} from 'protocol/api/billing_new/dto_services_new_pb';

export const isServiceChecked = ({
  product,
  serviceType,
  groupName,
}: {
  product: ProductModel;
  serviceType: ServiceType;
  groupName: ServiceGroupName;
}): boolean =>
  !!(
    product?.Services &&
    product.Services.find(
      (service) =>
        service.Group === groupName && service?.Types?.includes(serviceType),
    )
  );
