// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/core/svc_user.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { Empty, proto3 } from "@bufbuild/protobuf";
import { User } from "../../model/dto_user_pb.js";
import { Organization } from "../../model/dto_organization_pb.js";
import { Gender } from "../../model/dto_base_pb.js";

/**
 * @generated from message com.diagnocat.core.MyDataStreamReq
 */
export const MyDataStreamReq = proto3.makeMessageType(
  "com.diagnocat.core.MyDataStreamReq",
  [],
);

/**
 * @generated from message com.diagnocat.core.MyDataStreamResp
 */
export const MyDataStreamResp = proto3.makeMessageType(
  "com.diagnocat.core.MyDataStreamResp",
  () => [
    { no: 1, name: "HistoricalMe", kind: "message", T: User, oneof: "Update" },
    { no: 2, name: "HistoricalOrganizations", kind: "message", T: MyDataStreamResp_Organizations, oneof: "Update" },
    { no: 11, name: "UpdatedMe", kind: "message", T: User, oneof: "Update" },
    { no: 12, name: "UpdatedOrganization", kind: "message", T: Organization, oneof: "Update" },
    { no: 101, name: "Ping", kind: "message", T: Empty, oneof: "Update" },
  ],
);

/**
 * Organizations the user belongs to
 *
 * @generated from message com.diagnocat.core.MyDataStreamResp.Organizations
 */
export const MyDataStreamResp_Organizations = proto3.makeMessageType(
  "com.diagnocat.core.MyDataStreamResp.Organizations",
  () => [
    { no: 1, name: "Organizations", kind: "message", T: Organization, repeated: true },
  ],
  {localName: "MyDataStreamResp_Organizations"},
);

/**
 * @generated from message com.diagnocat.core.SetUserNameReq
 */
export const SetUserNameReq = proto3.makeMessageType(
  "com.diagnocat.core.SetUserNameReq",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "FirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "LastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetUserNameResp
 */
export const SetUserNameResp = proto3.makeMessageType(
  "com.diagnocat.core.SetUserNameResp",
  () => [
    { no: 1, name: "User", kind: "message", T: User },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetUserDateOfBirthReq
 */
export const SetUserDateOfBirthReq = proto3.makeMessageType(
  "com.diagnocat.core.SetUserDateOfBirthReq",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "DateOfBirth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetUserDateOfBirthResp
 */
export const SetUserDateOfBirthResp = proto3.makeMessageType(
  "com.diagnocat.core.SetUserDateOfBirthResp",
  () => [
    { no: 1, name: "User", kind: "message", T: User },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetUserGenderReq
 */
export const SetUserGenderReq = proto3.makeMessageType(
  "com.diagnocat.core.SetUserGenderReq",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Gender", kind: "enum", T: proto3.getEnumType(Gender) },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetUserGenderResp
 */
export const SetUserGenderResp = proto3.makeMessageType(
  "com.diagnocat.core.SetUserGenderResp",
  () => [
    { no: 1, name: "User", kind: "message", T: User },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetUserLanguageReq
 */
export const SetUserLanguageReq = proto3.makeMessageType(
  "com.diagnocat.core.SetUserLanguageReq",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetUserLanguageResp
 */
export const SetUserLanguageResp = proto3.makeMessageType(
  "com.diagnocat.core.SetUserLanguageResp",
  () => [
    { no: 1, name: "User", kind: "message", T: User },
  ],
);

