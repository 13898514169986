import { useState } from 'react';
import { notification } from 'antd';
import { ConnectError } from '@bufbuild/connect';

import { billingModel } from '@/entities/billing';
import { useAppDispatch } from '@/shared/hooks';

export const useSubscriptionExpiresModalForm = (productID: string) => {
  const dispatch = useAppDispatch();

  const [visible, toggleVisible] = useState(false);
  const [loading, toggleLoading] = useState(false);

  const setSubscriptionExpiresAfter = async (data: { minutes: number }) => {
    toggleLoading(true);
    const seconds = data.minutes * 60;

    if (productID) {
      try {
        await dispatch(billingModel.thunks.setExpireSubscriptionAfter({
          ProductStateID: productID,
          Seconds: BigInt(seconds)
        })).unwrap();

        toggleVisible(false);
      }
      catch (error) {
        if (error instanceof ConnectError) {
          notification.error({
            message: error?.rawMessage,
          });
        }
      }
      finally {
        toggleLoading(false);
      }
    }
  };

  return {
    loading,
    visible,
    toggleVisible,
    setSubscriptionExpiresAfter,
  };
};
