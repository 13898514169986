import React, { FC } from 'react';
import { Outlet } from 'react-router';
import { useParams } from 'react-router-dom';

import { useAccountInfoStream } from '@/entities/billing';

import { useOrganizationStream } from './hooks';

export const OrganizationContainer: FC = () => {
  const { organizationID } =
    useParams<{ organizationID: string; userID: string }>();

  useOrganizationStream(organizationID);
  useAccountInfoStream(organizationID);

  return <Outlet />;
};
