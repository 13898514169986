import { ServiceType } from 'protocol/api/billing_new/dto_services_new_pb';

export const SERVICE_GROUP_2D_ANALYSIS = [
  ServiceType.ServiceType_Order_IOXRay_GP_Analysis,
  ServiceType.ServiceType_Order_Panoramic_GP_Analysis,
];

export const SERVICE_GROUP_3D_ANALYSIS = [
  ServiceType.ServiceType_Order_CBCT_GP_Analysis,
  ServiceType.ServiceType_Order_CBCT_Endodontic_Analysis,
  ServiceType.ServiceType_Order_CBCT_Implantology_Analysis,
  ServiceType.ServiceType_Order_CBCT_ThirdMolar_Analysis,
  ServiceType.ServiceType_Order_CBCT_STL_Analysis,
  ServiceType.ServiceType_Order_CBCT_Orthodontic_Analysis,
  ServiceType.ServiceType_Order_CBCT_IOS_Superimposition_Analysis,
  ServiceType.ServiceType_Order_IOS_Segmentation_Analysis,
  ServiceType.ServiceType_Order_ImplantStudio_Analysis,
  ServiceType.ServiceType_Order_OrthoStudio_Analysis,
];

export const SERVICE_ENABLE_ONLY = [
  ServiceType.ServiceType_Enable_DesktopApp,
  ServiceType.ServiceType_Enable_STLViewer,
  ServiceType.ServiceType_Enable_CollaborationTool,
  ServiceType.ServiceType_Enable_3DModelsWithConditions,
];

export const SERVICE_GROUP_2D_UPLOADS = [
  ServiceType.ServiceType_Upload_IOXRay,
  ServiceType.ServiceType_Upload_Panorama,
  ServiceType.ServiceType_Upload_DentalPhoto,
];

export const SERVICE_GROUP_3D_UPLOADS = [
  ServiceType.ServiceType_Upload_CBCT,
  ServiceType.ServiceType_Upload_STL,
];

export const ALL_SERVICES = [
  ...SERVICE_GROUP_2D_ANALYSIS,
  ...SERVICE_GROUP_3D_ANALYSIS,
  ...SERVICE_GROUP_2D_UPLOADS,
  ...SERVICE_GROUP_3D_UPLOADS,
  ...SERVICE_ENABLE_ONLY,
];

export const VISIBLE_SERVICES = [
  ...SERVICE_GROUP_2D_ANALYSIS,
  ...SERVICE_GROUP_3D_ANALYSIS,
  ...SERVICE_ENABLE_ONLY,
];
