import { Order } from 'protocol/api/billing_new/dto_order_new_pb';
import { ProductState } from 'protocol/api/billing_new/dto_product_new_pb';

export const getOrderSubscriptionName = (
  order: Order,
  products: ProductState[],
): string => {
  const product = order?.Effect?.Effect;

  switch (product.case) {
    case 'SubscriptionAdd':
      return product.value.Subscription?.Name ?? '';
    case 'PackageAdd':
      return product.value.Package?.Name ?? '';
    case 'ProductStateRemove':
      return (
        products?.find(({ ID }) => ID === product.value.RemoveProductStateID)
          ?.Model?.Name ?? ''
      );
    case 'SubscriptionRenew':
      return (
        products?.find(
          ({ ID }) => ID === product.value.RenewSubscriptionStateID,
        )?.Model?.Name ?? ''
      );
    case 'SubscriptionReplace':
      return product.value.NewSubscription?.Name ?? '';
    case 'SubscriptionActivate':
      return (
        products?.find(
          ({ ID }) => ID === product.value.ActivateSubscriptionStateID,
        )?.Model?.Name ?? ''
      );
    case 'SubscriptionSetAutoRenewal':
      return (
        products?.find(({ ID }) => ID === product.value.SubscriptionStateID)
          ?.Model?.Name ?? ''
      );
    case 'SubscriptionSetAutoCharge':
      return (
        products?.find(({ ID }) => ID === product.value.SubscriptionStateID)
          ?.Model?.Name ?? ''
      );
    case 'AddonAdd':
    case 'BonusAdd':
      return product.value.Addon?.Name ?? '';
    default:
      return 'Unknown';
  }
};
