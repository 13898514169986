import { useEffect } from 'react';

import api from '@/shared/api/api';
import { useAppDispatch } from '@/shared/hooks';
import { userModel } from '@/entities/user';
import { Organization } from 'protocol/model/dto_organization_pb';
import { AdminUserInfoStreamResp_OrganizationUserSharingInfo } from 'protocol/api/core/svc_adminka_pb';

type UserDataAccumulators = {
  currentUserOrganizations: Organization[],
  sharedOrganizations: AdminUserInfoStreamResp_OrganizationUserSharingInfo[],
}
const dataAccumulators: UserDataAccumulators  = {
  currentUserOrganizations: [],
  sharedOrganizations: [],
};

let abortController: AbortController;

export const useAdminUserInfoStream = (userID: string) => {
  const dispatch = useAppDispatch();

  const openAdminUserInfoStream = async () => {
    abortController = new AbortController();

    dispatch(userModel.actions.setCurrentUserLoadingState('pending'));

    try {
      const adminUserInfoStream = api.core.admin.adminUserInfoStream(
        { UserID: userID }, 
        { signal: abortController.signal },
      );

      for await (const { Update } of adminUserInfoStream) {
        switch (Update.case) {
          case 'UpdatedUser':
          case 'HistoricalUser': {
            dispatch(userModel.actions.setCurrentUser(Update.value));
            dispatch(userModel.actions.setCurrentUserLoadingState('succeeded'));
            break;
          }

          case 'HistoricalOrganization': {
            dataAccumulators.currentUserOrganizations.push(Update.value);
            break;
          }

          case 'EndOfHistoricalOrganizations': {
            dispatch(userModel.actions.setCurrentUserOrganizations(dataAccumulators.currentUserOrganizations));
            dataAccumulators.currentUserOrganizations = [];
            break;
          }

          case 'UpdatedSharingInfo': {
            dispatch(userModel.actions.updateOne(Update.value));
            break;
          }
          case 'HistoricalSharingInfo': {
            dataAccumulators.sharedOrganizations.push(Update.value);
            break;
          }

          case 'EndOfHistoricalUserSharings': {
            dispatch(userModel.actions.setMany(dataAccumulators.sharedOrganizations));
            dataAccumulators.sharedOrganizations = [];
            break;
          }

          default: {
            // throw
            // throw new Error('Admin User Info Stream failed');
          }
        }
      }
    } catch (error) {
      console.error(
        'AdminUserInfoStream:: error: ',
        error.message,
        error.code,
        error?.type,
        error,
      );
    }
  };

  useEffect(() => {
    if (userID) {
      openAdminUserInfoStream();
    }

    return () => {
      abortController?.abort();

      dispatch(userModel.actions.resetCurrentUser({}));
      dispatch(userModel.actions.resetCurrentUserOrganizations({}));
    };
  }, [userID]);
};
