import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Breadcrumb,
  Button,
  Col,
  Empty,
  Form,
  notification,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';

import { useBillingZonesOptions } from '@/widgets/selectBillingZone';
import { BASE_URI, PATHS } from '@/shared/config';
import { Layout } from '@/shared/ui';
import { billingModel } from '@/entities/billing';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import {
  GroupNamesDefaultMessage,
  lifeTimeMonthDefaultMessages,
  ServiceNamesDefaultMessage,
} from '@/shared/config/i18n';
import { productEditorModel } from '@/entities/productEditor';
import { EditorModal } from '@/pages/ProductEditor/ui/EditorModal/EditorModal';
import { Header } from '@/widgets/header';
import { ProductModel } from 'protocol/api/billing_new/dto_product_new_pb';
import { authModel } from '@/entities/auth';

import styles from './ProductEditor.module.scss';

const { Title } = Typography;

export const ProductEditor: FC = () => {
  const dispatch = useAppDispatch();

  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false);

  const { formatMessage, formatNumber } = useIntl();

  const loading = useAppSelector(
    productEditorModel.selectors.selectProductEditorLoading,
  );
  const products = useAppSelector(productEditorModel.selectors.selectAll);

  const billingZonesOptions = useBillingZonesOptions();

  const onChangeBillingZone = async (newBillingZone: string) => {
    dispatch(productEditorModel.actions.setProductLoading('pending'));
    try {
      dispatch(productEditorModel.actions.removeAll());

      const { AvailableProducts } = await dispatch(
        billingModel.thunks.getAvailableProducts({
          Condition: {
            case: 'ByBillingZone',
            value: newBillingZone,
          },
        }),
      ).unwrap();

      dispatch(productEditorModel.actions.setBillingZone(newBillingZone));
      dispatch(productEditorModel.actions.setProductLoading('succeeded'));
      dispatch(productEditorModel.actions.setMany(AvailableProducts));
    } catch (e) {
      dispatch(productEditorModel.actions.setProductLoading('failed'));
    }
  };

  const getProductLifetime = (productKind: ProductModel['Kind']) => {
    switch (productKind.case) {
      case 'Subscription': {
        return productKind.value.LifeTimeDays > 0
          ? formatMessage(
              {
                id: 'product.lifetime.days',
                defaultMessage:
                  '{days, plural, =0 {0 days} one {One day} other {{days} days}}',
              },
              { days: productKind.value.LifeTimeDays },
            )
          : formatMessage(
              lifeTimeMonthDefaultMessages[productKind.value.LifeTimeMonths],
            );
      }
      case 'Package': {
        return productKind.value.LifeTimeDays > 0
          ? formatMessage(
              {
                id: 'product.lifetime.days',
                defaultMessage:
                  '{days, plural, =0 {0 days} one {One day} other {{days} days}}',
              },
              { days: productKind.value.LifeTimeDays },
            )
          : formatMessage(
              lifeTimeMonthDefaultMessages[productKind.value.LifeTimeMonths],
            );
      }
      default: {
        return 'Unexpected lifetime';
      }
    }
  };

  const downloadProductInJSON = async () => {
    await dispatch(authModel.thunks.refresh({}));

    const response = await fetch(
      `${BASE_URI}/api/billing/downloadProductModels`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          Models: products.map((product) => product?.toJson()),
        }),
      },
    );

    if (response.status === 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');

      a.href = url;
      a.download = 'products.json';
      a.click();
    } else {
      notification.error({
        message: formatMessage({
          id: 'markAsPaidModal.error',
          defaultMessage: 'Something went wrong',
        }),
      });
    }
  };

  return (
    <Layout>
      <Header />

      <Layout.Content>
        <Breadcrumb
          style={{ padding: '24px 0' }}
          className="p2"
          items={[
            {
              title: (
                <Link
                  className={styles.breadcrumb}
                  to={generatePath(PATHS.main)}
                >
                  {formatMessage({
                    id: 'breadcrumb.home',
                    defaultMessage: 'Home',
                  })}
                </Link>
              ),
            },
          ]}
        />

        <Row gutter={16} style={{ rowGap: 24 }}>
          <Col md={16} lg={8}>
            <Space direction="vertical" size="large">
              <Form.Item
                name="billingZone"
                label={formatMessage({
                  id: 'userProfile.billingZone',
                  defaultMessage: 'Billing Zone',
                })}
              >
                <Select
                  style={{ width: '350px' }}
                  className={styles.billingZoneSelect}
                  options={billingZonesOptions}
                  loading={loading === 'pending'}
                  onChange={onChangeBillingZone}
                />
              </Form.Item>
            </Space>
          </Col>
        </Row>

        <Row gutter={16} style={{ rowGap: 24 }}>
          <Col md={16} lg={10}>
            {products.length > 0 && (
              <div className={styles.addProductButtons}>
                <Button
                  type="primary"
                  style={{ marginBottom: '16px' }}
                  onClick={() => {
                    setIsEditorModalOpen(true);

                    dispatch(productEditorModel.actions.setEditorMode('add'));
                    dispatch(
                      productEditorModel.actions.setProductForUpdate(
                        new ProductModel({
                          CrossedOutPrice: '0',
                          AvailableForAdminsOnly: true,
                          Kind: { case: 'Subscription', value: {} },
                        }),
                      ),
                    );
                  }}
                >
                  {formatMessage({
                    id: 'productEditor.addNewProduct.subscription',
                    defaultMessage: 'Add new subscription',
                  })}
                </Button>

                <Button
                  type="primary"
                  style={{ marginBottom: '16px' }}
                  onClick={() => {
                    setIsEditorModalOpen(true);

                    dispatch(productEditorModel.actions.setEditorMode('add'));
                    dispatch(
                      productEditorModel.actions.setProductForUpdate(
                        new ProductModel({
                          CrossedOutPrice: '0',
                          AvailableForAdminsOnly: true,
                          Kind: { case: 'Package', value: {} },
                        }),
                      ),
                    );
                  }}
                >
                  {formatMessage({
                    id: 'productEditor.addNewProduct.package',
                    defaultMessage: 'Add new package',
                  })}
                </Button>
              </div>
            )}
            {products.length > 0 ? (
              products.map((product) => {
                const {
                  SKU,
                  Name,
                  Price,
                  Currency,
                  Kind: productKind,
                  Services,
                } = product;

                return (
                  <div key={SKU} className={styles.product}>
                    <div>
                      <Title level={5}>
                        {productKind.case === 'Subscription' &&
                          formatMessage({
                            id: 'global.subscription',
                            defaultMessage: 'Subscription',
                          })}
                        {productKind.case === 'Package' &&
                          formatMessage({
                            id: 'global.package',
                            defaultMessage: 'Package',
                          })}
                        {' | '}
                        {Name}
                        {' | '}
                        {formatNumber(Number(Price ?? '0'), {
                          maximumFractionDigits: 0,
                          style: 'currency',
                          currencyDisplay: 'narrowSymbol',
                          currency: Currency,
                        })}
                        {' | '}
                        {getProductLifetime(productKind)}
                      </Title>

                      <ul>
                        {Services?.map(({ Types, Group, Kind }) => {
                          const amount =
                            Kind.case === 'Countable'
                              ? Number(Kind.value.Limit)
                              : 0;

                          return (
                            <li key={`${Types.toString()}`}>
                              {Types.length > 1
                                ? formatMessage(
                                    GroupNamesDefaultMessage[Group],
                                    {
                                      amount,
                                    },
                                  )
                                : formatMessage(
                                    ServiceNamesDefaultMessage[Types.at(0)],
                                    {
                                      amount,
                                    },
                                  )}
                              {Types.length > 1 && (
                                <ul>
                                  {Types.map((type) => (
                                    <li>
                                      {formatMessage(
                                        ServiceNamesDefaultMessage[type],
                                        { amount: 0 },
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>

                    <div className={styles.buttons}>
                      <Button
                        danger
                        type="default"
                        onClick={() =>
                          dispatch(
                            productEditorModel.actions.removeProduct(
                              product.SKU,
                            ),
                          )
                        }
                      >
                        {formatMessage({
                          id: 'global.remove',
                          defaultMessage: 'Remove',
                        })}
                      </Button>

                      <Button
                        type="default"
                        onClick={() => {
                          dispatch(
                            productEditorModel.actions.setProductForUpdate(
                              product,
                            ),
                          );

                          dispatch(
                            productEditorModel.actions.setEditorMode('copy'),
                          );

                          setIsEditorModalOpen(true);
                        }}
                      >
                        {formatMessage({
                          id: 'global.copy',
                          defaultMessage: 'Copy',
                        })}
                      </Button>

                      <Button
                        type="primary"
                        onClick={() => {
                          dispatch(
                            productEditorModel.actions.setProductForUpdate(
                              product,
                            ),
                          );

                          setIsEditorModalOpen(true);
                        }}
                      >
                        {formatMessage({
                          id: 'global.edit',
                          defaultMessage: 'Edit',
                        })}
                      </Button>
                    </div>
                  </div>
                );
              })
            ) : (
              <Empty
                description={formatMessage({
                  id: 'global.empty',
                  defaultMessage: 'No data',
                })}
              />
            )}
          </Col>
        </Row>

        {products.length > 0 && (
          <Row gutter={16} style={{ rowGap: 24 }}>
            <Col md={16} lg={8}>
              <Button
                disabled={products.length === 0}
                type="primary"
                onClick={downloadProductInJSON}
              >
                {formatMessage({
                  id: 'global.download',
                  defaultMessage: 'Download',
                })}
              </Button>
            </Col>
          </Row>
        )}
      </Layout.Content>

      {isEditorModalOpen && (
        <EditorModal
          isOpen={isEditorModalOpen}
          onClose={() => setIsEditorModalOpen(false)}
        />
      )}
    </Layout>
  );
};
