import { FC } from 'react';
import { Form, Modal, InputNumber } from 'antd';
import { useIntl } from 'react-intl';

export interface SubscriptionExpiresModalFormValues {
  minutes: number;
}

interface SubscriptionExpiresModalFormProps {
  visible: boolean;
  onSubmit: (values: SubscriptionExpiresModalFormValues) => void;
  confirmLoading: boolean;
  onCancel: () => void;
}

export const SubscriptionExpiresModalForm: FC<
  SubscriptionExpiresModalFormProps
> = ({ visible, confirmLoading, onSubmit, onCancel }) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  return (
    <Modal
      width={300}
      open={visible}
      title={formatMessage({
        id: 'form.setExpires.subscription',
        defaultMessage: 'Set expires subscription',
      })}
      okText={
        <span>
          {formatMessage({ id: 'global.set', defaultMessage: 'Set' })}
        </span>
      }
      cancelText={formatMessage({
        id: 'global.cancel',
        defaultMessage: 'Cancel',
      })}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onSubmit(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="set-subscription-expires">
        <Form.Item
          label={formatMessage({
            id: 'global.minutes',
            defaultMessage: 'Minutes',
          })}
          name="minutes"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'forms.required',
                defaultMessage: 'Field is required',
              }),
            },
          ]}
        >
          <InputNumber min={1} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
