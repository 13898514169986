// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_organization.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { OrganizationJobPosition } from "./dto_organization_job_position_pb.js";
import { OrganizationPermissions } from "./dto_organization_permissions_pb.js";
import { Attribute, Created, Deleted, Revision } from "./dto_base_pb.js";

/**
 * @generated from enum com.diagnocat.model.DentalNotationFormat
 */
export const DentalNotationFormat = proto3.makeEnum(
  "com.diagnocat.model.DentalNotationFormat",
  [
    {no: 0, name: "DentalNotationFormatISO"},
    {no: 1, name: "DentalNotationFormatUniversal"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.OrganizationType
 */
export const OrganizationType = proto3.makeEnum(
  "com.diagnocat.model.OrganizationType",
  [
    {no: 0, name: "OrganizationTypeInvalidValue"},
    {no: 1, name: "OrganizationTypeClinic"},
    {no: 2, name: "OrganizationTypeDiagnosticCenter"},
    {no: 3, name: "OrganizationTypeSoloPractitioner"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.OrganizationUserRoleType
 */
export const OrganizationUserRoleType = proto3.makeEnum(
  "com.diagnocat.model.OrganizationUserRoleType",
  [
    {no: 0, name: "OrganizationUserRoleTypeInvalidValue"},
    {no: 1, name: "OrganizationUserRoleTypeOwner"},
    {no: 2, name: "OrganizationUserRoleTypeClinicalLeader"},
    {no: 3, name: "OrganizationUserRoleTypeTreatingDoctor"},
    {no: 4, name: "OrganizationUserRoleTypeNonClinicalMember"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.FeatureFlag
 */
export const FeatureFlag = proto3.makeEnum(
  "com.diagnocat.model.FeatureFlag",
  [
    {no: 0, name: "FeatureFlag_InvalidValue"},
    {no: 2, name: "FeatureFlag_Show_BillingShop"},
    {no: 3, name: "FeatureFlag_Show_NonDentalFindings"},
    {no: 7, name: "FeatureFlag_Show_SegmentronConditionsButtons"},
    {no: 8, name: "FeatureFlag_Show_NotForCommercialUseBanner"},
    {no: 9, name: "FeatureFlag_Show_IOSReportButton"},
    {no: 10, name: "FeatureFlag_Show_OrthoStudioAddSecondaryCBCT"},
    {no: 51, name: "FeatureFlag_Enable_SegmentronSerialisation"},
    {no: 61, name: "FeatureFlag_FDA_SubmissionView"},
    {no: 62, name: "FeatureFlag_FDA_NonAidedVersion"},
    {no: 101, name: "FeatureFlag_Hide_Probabilities"},
    {no: 102, name: "FeatureFlag_Hide_ConditionsMasks"},
    {no: 103, name: "FeatureFlag_Hide_PerioMasksAndSlices"},
    {no: 104, name: "FeatureFlag_Hide_CariesSigns"},
    {no: 105, name: "FeatureFlag_Hide_PBLRulesAndMeasurements"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.FeatureFlagType
 */
export const FeatureFlagType = proto3.makeEnum(
  "com.diagnocat.model.FeatureFlagType",
  [
    {no: 0, name: "FeatureFlagType_InvalidValue"},
    {no: 1, name: "FeatureFlagType_Development"},
    {no: 2, name: "FeatureFlagType_Regulatory"},
  ],
);

/**
 * @generated from message com.diagnocat.model.Organization
 */
export const Organization = proto3.makeMessageType(
  "com.diagnocat.model.Organization",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Type", kind: "enum", T: proto3.getEnumType(OrganizationType) },
    { no: 4, name: "ParentID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "LogoAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "StampAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "Contacts", kind: "message", T: OrganizationContacts },
    { no: 8, name: "Settings", kind: "message", T: OrganizationSettings },
    { no: 9, name: "AvailableJobPositions", kind: "enum", T: proto3.getEnumType(OrganizationJobPosition), repeated: true },
    { no: 10, name: "UserRoles", kind: "message", T: OrganizationUserRole, repeated: true },
    { no: 11, name: "FeatureFlags", kind: "message", T: OrganizationFeatureFlag, repeated: true },
    { no: 10020, name: "YourPermissions", kind: "message", T: OrganizationPermissions },
    { no: 10030, name: "Created", kind: "message", T: Created },
    { no: 10040, name: "Revision", kind: "message", T: Revision },
    { no: 10050, name: "Deleted", kind: "message", T: Deleted },
  ],
);

/**
 * TODO - needs product investigation
 *
 * @generated from message com.diagnocat.model.OrganizationSettings
 */
export const OrganizationSettings = proto3.makeMessageType(
  "com.diagnocat.model.OrganizationSettings",
  () => [
    { no: 1, name: "Hack_EditUsersContacts", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "OnlyTeamMembersCanViewPatient", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "OnlyTeamMembersCanViewPersonalData", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "OnlyTeamMembersCanRequestReport", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "OnlyTeamMembersCanEditPatient", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "OnlyTeamMembersCanEditReport", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "DentalNotationFormat", kind: "enum", T: proto3.getEnumType(DentalNotationFormat) },
  ],
);

/**
 * @generated from message com.diagnocat.model.OrganizationContacts
 */
export const OrganizationContacts = proto3.makeMessageType(
  "com.diagnocat.model.OrganizationContacts",
  () => [
    { no: 1, name: "Country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "City", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "Address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "Phones", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "Emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "WebSite", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "ZipCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.OrganizationUserRole
 */
export const OrganizationUserRole = proto3.makeMessageType(
  "com.diagnocat.model.OrganizationUserRole",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "PersonalData", kind: "message", T: OrganizationDefinedPersonalData },
    { no: 3, name: "Roles", kind: "enum", T: proto3.getEnumType(OrganizationUserRoleType), repeated: true },
    { no: 4, name: "JobPositions", kind: "enum", T: proto3.getEnumType(OrganizationJobPosition), repeated: true },
    { no: 5, name: "StampAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "CreatedAt", kind: "message", T: Timestamp },
    { no: 21, name: "Attributes", kind: "message", T: Attribute, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.OrganizationDefinedPersonalData
 */
export const OrganizationDefinedPersonalData = proto3.makeMessageType(
  "com.diagnocat.model.OrganizationDefinedPersonalData",
  () => [
    { no: 1, name: "FirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "LastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.OrganizationFeatureFlag
 */
export const OrganizationFeatureFlag = proto3.makeMessageType(
  "com.diagnocat.model.OrganizationFeatureFlag",
  () => [
    { no: 1, name: "Flag", kind: "enum", T: proto3.getEnumType(FeatureFlag) },
    { no: 2, name: "Value", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "ReadOnly", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "Type", kind: "enum", T: proto3.getEnumType(FeatureFlagType) },
  ],
);

