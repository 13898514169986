import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Input, Select, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import Table, { ColumnsType, TableProps } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import { SortOrder } from 'antd/es/table/interface';

import { AdminUserListStreamSortBy } from 'protocol/api/core/svc_adminka_pb';
import { Layout } from '@/shared/ui';
import { useAppSelector, useSearch } from '@/shared/hooks';
import { BASE_URI, i18n, PATHS } from '@/shared/config';
import { usersModel, useUserListStream } from '@/entities/users';
import { Header } from '@/widgets/header';
import { Footer } from '@/widgets/footer';

import styles from './Home.module.scss';

const { Search } = Input;

type UsersTableDataItem = {
  id: string;
  key: string;
  email: string;
  country: string;
  created: string;
};

export const useUsersTableData = (): UsersTableDataItem[] => {
  const { formatMessage, formatDate } = useIntl();
  const users = useAppSelector(usersModel.selectors.selectAll);

  return useMemo(
    () =>
      users.map((user): UsersTableDataItem => {
        const country =
          user.Settings.Country in i18n.countries
            ? // @ts-ignore
              formatMessage(i18n.countries[user.Settings.Country]) // Country as string is a weak, because it's unclear how we should sync countries list between backend and frontend
            : user.Settings.Country; // formatMessage({ id: 'countries.unknown', defaultMessage: 'Unknown' });

        return {
          id: user.ID,
          key: user.ID,
          email: user.PersonalData.Emails[0],
          created: formatDate(
            new Date(Number(user?.Created?.At?.seconds) * 1000),
            {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            },
          ),
          country,
        };
      }),
    [users, formatMessage, formatDate],
  );
};

const useSortUsersTable = () => {
  const [sortOrder, setSortOrder] = useState<SortOrder>();
  const [sortBy, setSortBy] = useState<AdminUserListStreamSortBy>();

  const handleTableSortChange: TableProps<UsersTableDataItem>['onChange'] = (
    pagination,
    filters,
    sorter,
  ) => {
    if (sorter.field === 'email') {
      setSortBy(AdminUserListStreamSortBy.AdminUserListStreamSortByEmail);
      setSortOrder(sorter.order);
    }

    if (sorter.field === undefined) {
      setSortBy(undefined);
      setSortOrder(undefined);
    }
  };

  return {
    sortBy,
    sortOrder,
    handleTableSortChange,
  };
};

export const Home: FC = () => {
  const { formatMessage } = useIntl();
  const users = useAppSelector(usersModel.selectors.selectAll);
  const loading = useAppSelector(usersModel.selectors.selectLoading);

  const { openUserListStream, closeUserListStream } = useUserListStream();
  const usersTableData = useUsersTableData();
  const { search, setSearch, debouncedSearch } = useSearch();
  const { sortBy, sortOrder, handleTableSortChange } = useSortUsersTable();

  const lastUserID = useMemo(() => users.at(-1)?.ID, [users]);

  const handleShowMore = useCallback(() => {
    closeUserListStream(false);

    openUserListStream({
      startFromUserID: lastUserID,
      search: debouncedSearch,
      sortBy,
      sortOrder,
    });
  }, [debouncedSearch, lastUserID]);

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const columns: ColumnsType<UsersTableDataItem> = [
    {
      key: 'email',
      title: <FormattedMessage id="users.email" defaultMessage="E-mail" />,
      dataIndex: 'email',
      sorter: true,
    },
    {
      key: 'country',
      title: <FormattedMessage id="users.country" defaultMessage="Country" />,
      dataIndex: 'country',
      // sorter: true,
    },
    {
      key: 'created',
      title: <FormattedMessage id="global.created" defaultMessage="Created" />,
      dataIndex: 'created',
    },
    {
      title: <FormattedMessage id="users.action" defaultMessage="Action" />,
      key: 'action',
      render: (_, record) => (
        <Link className={styles.profileLink} to={generatePath(PATHS.user, { userID: record.id })}>
          <FormattedMessage id="users.profileLink" defaultMessage="Profile" />
        </Link>
      ),
    },
  ];

  const pushToCreateUserPage = () => {
    window.open(`${BASE_URI}/sign-up`, '_blank');
  };

  useEffect(() => {
    openUserListStream({
      search: debouncedSearch,
      sortBy,
      sortOrder,
    });

    return () => {
      closeUserListStream();
    };
  }, [debouncedSearch, sortBy, sortOrder]);

  return (
    <Layout>
      <Header />

      <Layout.Content innerClassName={styles.container}>
        <div className={styles.pageHeader}>
          <Search
            className={styles.search}
            placeholder={formatMessage({
              id: 'users.search',
              defaultMessage: 'Search by ID, date, country or duration',
            })}
            value={search}
            onChange={handleChangeSearch}
          />

          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={pushToCreateUserPage}
          >
            {formatMessage({
              id: 'users.createAccount',
              defaultMessage: 'Create Account',
            })}
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={usersTableData}
          loading={loading}
          pagination={false}
          onChange={handleTableSortChange}
        />

        <Button
          type="text"
          onClick={handleShowMore}
          style={{ marginRight: 'auto', marginLeft: 'auto' }}
        >
          <FormattedMessage id="users.showMore" defaultMessage="Show more" />
        </Button>
      </Layout.Content>

      <Footer />
    </Layout>
  );
};
