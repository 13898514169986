import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { PartialMessage } from '@bufbuild/protobuf';

import { LoadingStateType, SliceName } from '@/shared/config';
import { ProductModel } from 'protocol/api/billing_new/dto_product_new_pb';
import {
  Service,
  Service_CountableKind,
  Service_EnablerKind,
  ServiceGroupName,
  ServiceType,
} from 'protocol/api/billing_new/dto_services_new_pb';

import { SERVICE_ENABLE_ONLY, VISIBLE_SERVICES } from '../config';

type EditorModeType = 'edit' | 'add' | 'copy';

export const productEditorAdapter = createEntityAdapter<ProductModel>({
  selectId: (product) => product.SKU,
});

const initialState = productEditorAdapter.getInitialState({
  productForUpdate: {} as ProductModel,
  editorMode: 'edit' as EditorModeType,
  billingZone: '',
  loading: 'idle' as LoadingStateType,
});
type ProductEditorStateType = typeof initialState;

const productEditorSlice = createSlice({
  name: SliceName.productEditor,
  initialState,
  reducers: {
    setMany: (state, { payload }: PayloadAction<ProductModel[]>) => {
      productEditorAdapter.setMany(state as ProductEditorStateType, payload);
    },
    setOne: (
      state,
      { payload }: PayloadAction<PartialMessage<ProductModel>>,
    ) => {
      productEditorAdapter.setOne(
        state as ProductEditorStateType,
        new ProductModel(payload),
      );
    },
    addOne: (state, { payload }: PayloadAction<ProductModel>) => {
      productEditorAdapter.addOne(state as ProductEditorStateType, payload);
    },
    removeAll: (state) => {
      productEditorAdapter.removeAll(state as ProductEditorStateType);
    },
    setProductForUpdate: (
      state,
      { payload }: PayloadAction<PartialMessage<ProductModel>>,
    ) => {
      state.productForUpdate = new ProductModel(payload);
    },
    removeProduct: (state, { payload }: PayloadAction<string>) => {
      productEditorAdapter.removeOne(state as ProductEditorStateType, payload);
    },
    setEditorMode: (state, { payload }: PayloadAction<EditorModeType>) => {
      state.editorMode = payload;
    },
    setBillingZone: (state, { payload }: PayloadAction<string>) => {
      state.billingZone = payload;
    },
    setProductLoading: (
      state,
      { payload }: PayloadAction<LoadingStateType>,
    ) => {
      state.loading = payload;
    },
    setGroupServiceLimits: (
      state,
      {
        payload,
      }: PayloadAction<{
        groupName: ServiceGroupName;
        value: number;
      }>,
    ) => {
      const targetService = state.productForUpdate.Services.find(
        (service) => service.Group === payload.groupName,
      );

      if (targetService && targetService.Kind.case === 'Countable') {
        const newService = new Service({
          ...targetService,
          Kind: {
            case: 'Countable',
            value: new Service_CountableKind({
              Limit: BigInt(payload.value),
              Leftover: BigInt(payload.value),
            }),
          },
        });

        const newServices = state.productForUpdate.Services.filter(
          (service) => service.Group !== payload.groupName,
        ).concat(newService);

        const newProduct = new ProductModel({
          ...state.productForUpdate,
          Services: newServices,
        } as PartialMessage<ProductModel>);

        state.productForUpdate = newProduct;
      }
    },
    toggleGroupService: (
      state,
      {
        payload,
      }: PayloadAction<{ type: ServiceType; groupName: ServiceGroupName }>,
    ) => {
      const targetService = state.productForUpdate.Services.find(
        (service) => service.Group === payload.groupName,
      );

      targetService.Types = targetService.Types.includes(payload.type)
        ? targetService.Types.filter(
            (serviceType) => serviceType !== payload.type,
          )
        : targetService.Types.concat(payload.type);

      const newProduct = {
        ...state.productForUpdate,
        Services: state.productForUpdate.Services.filter(
          (service) => service.Group !== targetService.Group,
        ).concat(targetService),
      };

      state.productForUpdate = newProduct;
    },
    addGroupService: (
      state,
      {
        payload,
      }: PayloadAction<{ type: ServiceType; groupName: ServiceGroupName }>,
    ) => {
      const newProduct = {
        ...state.productForUpdate,
        Services: state.productForUpdate.Services.concat(
          new Service({
            Group: payload.groupName,
            Types: [payload.type],
            Visible: VISIBLE_SERVICES.includes(payload.type),
            Kind: {
              case: 'Countable',
              value: new Service_CountableKind({
                Limit: BigInt(0),
                Leftover: BigInt(0),
              }),
            },
          }),
        ),
      };

      state.productForUpdate = newProduct;
    },
    removeGroupService: (
      state,
      { payload }: PayloadAction<ServiceGroupName>,
    ) => {
      const newProduct = {
        ...state.productForUpdate,
        Services: state.productForUpdate.Services.filter(
          (service) => service.Group !== payload,
        ),
      };

      state.productForUpdate = newProduct;
    },
    setService: (state, { payload }: PayloadAction<ServiceType>) => {
      const isEnablerService = SERVICE_ENABLE_ONLY.includes(payload);
      const productCopy = { ...state.productForUpdate };

      const newService = new Service({
        Group: ServiceGroupName.ServiceGroupNameEmpty,
        Types: [payload],
        Visible: VISIBLE_SERVICES.includes(payload),
        // @ts-expect-error WARN: TS can't infer the type, which complicates the code, to avoid unnecessary calls to constructors and type assertions a warning is set
        Kind: {
          case: isEnablerService ? 'Enabler' : 'Countable',
          value: isEnablerService
            ? new Service_EnablerKind({})
            : new Service_CountableKind({
                Limit: BigInt(0),
                Leftover: BigInt(0),
              }),
        },
      });
      productCopy.Services.push(newService);

      state.productForUpdate = productCopy;
    },
    removeService: (state, { payload }: PayloadAction<ServiceType>) => {
      const productCopy = {
        ...state.productForUpdate,
        Services: state.productForUpdate.Services.filter(
          (service) => service.Types[0] !== payload,
        ),
      };

      state.productForUpdate = productCopy;
    },
    setServiceLimits: (
      state,
      { payload }: PayloadAction<{ type: ServiceType; limit: number }>,
    ) => {
      const productCopy = { ...state.productForUpdate };
      const targetService = productCopy.Services.find(
        (service) => service.Types[0] === payload.type,
      );

      if (targetService) {
        targetService.Kind.value = new Service_CountableKind({
          Limit: BigInt(payload.limit ?? 0),
          Leftover: BigInt(payload.limit ?? 0),
        });

        state.productForUpdate = productCopy;
      }
    },
  },
});

export const { actions } = productEditorSlice;

export default productEditorSlice.reducer;
