export type OrderStatus = 'paid' | 'new' | 'overdue';

export const getOrderStatus = ({ isPaid, isOverdue }: { isPaid: boolean, isOverdue: boolean }): OrderStatus => {
  if (isPaid) {
    return 'paid';
  }

  if (isOverdue) {
    return 'overdue';
  }

  return 'new';
};

