// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Services-module__servicesList--2qwURSX-{display:flex;flex-direction:column;gap:8px}.Services-module__serviceContainer--80r2Whxv{display:flex;justify-content:space-between;gap:16px}", "",{"version":3,"sources":["webpack://./src/pages/ProductEditor/ui/Services/Services.module.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,6CACE,YAAA,CACA,6BAAA,CACA,QAAA","sourcesContent":[".servicesList {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.serviceContainer {\n  display: flex;\n  justify-content: space-between;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"servicesList": "Services-module__servicesList--2qwURSX-",
	"serviceContainer": "Services-module__serviceContainer--80r2Whxv"
};
export default ___CSS_LOADER_EXPORT___;
