// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ServicesWithGroups-module__groupTitle--iDD7YyZa{display:flex;justify-content:space-between;align-items:center;margin-bottom:8px}.ServicesWithGroups-module__groupLimits--scchDtz5{display:flex;gap:8px}.ServicesWithGroups-module__serviceList--HiM0RXBF{display:flex;flex-direction:column;gap:8px}.ServicesWithGroups-module__serviceItem--dihrxuQL{display:flex;justify-content:space-between}.ServicesWithGroups-module__icon--HXSOx1i5{padding:4px}", "",{"version":3,"sources":["webpack://./src/pages/ProductEditor/ui/ServicesWithGroups/ServicesWithGroups.module.scss"],"names":[],"mappings":"AAAA,iDACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CAGF,kDACE,YAAA,CACA,OAAA,CAGF,kDACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,kDACE,YAAA,CACA,6BAAA,CAGF,2CACE,WAAA","sourcesContent":[".groupTitle {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n}\n\n.groupLimits {\n  display: flex;\n  gap: 8px;\n}\n\n.serviceList {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.serviceItem {\n  display: flex;\n  justify-content: space-between;\n}\n\n.icon {\n  padding: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groupTitle": "ServicesWithGroups-module__groupTitle--iDD7YyZa",
	"groupLimits": "ServicesWithGroups-module__groupLimits--scchDtz5",
	"serviceList": "ServicesWithGroups-module__serviceList--HiM0RXBF",
	"serviceItem": "ServicesWithGroups-module__serviceItem--dihrxuQL",
	"icon": "ServicesWithGroups-module__icon--HXSOx1i5"
};
export default ___CSS_LOADER_EXPORT___;
