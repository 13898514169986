// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BillingHistoryCell-module__container--552uqbVX{display:flex;flex-direction:column;gap:4px}.BillingHistoryCell-module__label--Mi5aGJ3u{font-size:12px;color:var(--grey9)}.BillingHistoryCell-module__text--tyze-e8E{width:130px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;font-size:14px}", "",{"version":3,"sources":["webpack://./src/widgets/billingHistory/ui/billingHistoryCell/BillingHistoryCell.module.scss"],"names":[],"mappings":"AAAA,gDACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,4CACE,cAAA,CACA,kBAAA,CAGF,2CACE,WAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,cAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.label {\n  font-size: 12px;\n  color: var(--grey9);\n}\n\n.text {\n  width: 130px;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BillingHistoryCell-module__container--552uqbVX",
	"label": "BillingHistoryCell-module__label--Mi5aGJ3u",
	"text": "BillingHistoryCell-module__text--tyze-e8E"
};
export default ___CSS_LOADER_EXPORT___;
