import { RootState } from '@/app/model/store';

import { productEditorAdapter } from './productEditorSlice';

const productEditorSelectors = productEditorAdapter.getSelectors(
  (state: RootState) => state.productEditor,
);

export const selectProductEditorLoading = (state: RootState) => state.productEditor.loading;

export const selectProductForUpdate = (state: RootState) => state.productEditor.productForUpdate;

export const selectEditorMode = (state: RootState) => state.productEditor.editorMode;

export const selectBillingZone = (state: RootState) => state.productEditor.billingZone;

export const { selectAll } = productEditorSelectors;
