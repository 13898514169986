// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddPackages-module__breadcrumb--6bDqRxTa:hover{color:var(--purpleflat) !important;background-color:transparent !important;text-decoration:none !important}", "",{"version":3,"sources":["webpack://./src/pages/AddPackages/AddPackages.module.scss"],"names":[],"mappings":"AACE,gDACE,kCAAA,CACA,uCAAA,CACA,+BAAA","sourcesContent":[".breadcrumb {\n  &:hover {\n    color: var(--purpleflat)!important;\n    background-color: transparent!important;\n    text-decoration: none!important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": "AddPackages-module__breadcrumb--6bDqRxTa"
};
export default ___CSS_LOADER_EXPORT___;
