// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".User-module__space--N8zoJBs-{display:flex}.User-module__breadcrumb--SzAexIDS:hover{color:var(--purpleflat) !important;background-color:transparent !important;text-decoration:none !important}.User-module__organizationLink--w1BsmbHJ{color:var(--purpleflat)}", "",{"version":3,"sources":["webpack://./src/pages/User/User.module.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA,CAIA,yCACE,kCAAA,CACA,uCAAA,CACA,+BAAA,CAIJ,yCACE,uBAAA","sourcesContent":[".space {\n  display: flex;\n}\n\n.breadcrumb {\n  &:hover {\n    color: var(--purpleflat)!important;\n    background-color: transparent!important;\n    text-decoration: none!important;\n  }\n}\n\n.organizationLink {\n  color: var(--purpleflat);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": "User-module__space--N8zoJBs-",
	"breadcrumb": "User-module__breadcrumb--SzAexIDS",
	"organizationLink": "User-module__organizationLink--w1BsmbHJ"
};
export default ___CSS_LOADER_EXPORT___;
