import React, { FC } from 'react';
import { Checkbox, Col, Input, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import {
  GroupNamesDefaultMessage,
  ServiceNamesDefaultMessage,
} from '@/shared/config/i18n';
import {
  ServiceGroupName,
  ServiceType,
} from 'protocol/api/billing_new/dto_services_new_pb';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { productEditorModel } from '@/entities/productEditor';
import { isServiceChecked } from '@/pages/ProductEditor/utils/utils';

import styles from './ServicesWithGroups.module.scss';

const { Title } = Typography;

type GroupWithServicesProps = {
  services: ServiceType[];
  groupName: ServiceGroupName;
};

export const ServicesWithGroups: FC<GroupWithServicesProps> = (props) => {
  const { services, groupName } = props;

  const productForUpdate = useAppSelector(
    productEditorModel.selectors.selectProductForUpdate,
  );

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const targetService = productForUpdate?.Services?.find(
    (service) => service.Group === groupName,
  );

  const limit = Number(
    targetService &&
      targetService.Kind.case === 'Countable' &&
      targetService.Kind.value.Limit,
  );

  const onServiceChange = (event: CheckboxChangeEvent, service: ServiceType) => {
    if (targetService?.Types?.length === 1 && !event.target.checked) {
      dispatch(productEditorModel.actions.removeGroupService(groupName));
    } else if (!targetService && event.target.checked) {
      dispatch(
        productEditorModel.actions.addGroupService({
          type: service,
          groupName,
        }),
      );
    } else {
      dispatch(
        productEditorModel.actions.toggleGroupService({
          type: service,
          groupName,
        }),
      );
    }
  };

  return (
    <Col md={16} lg={16} style={{ marginBottom: 16 }}>
      <div className={styles.groupTitle}>
        <Title level={5} style={{ marginBottom: 0 }}>
          {formatMessage({
            id: 'productEditor.editor.services.group.title',
            defaultMessage: 'Group',
          })}
          {': '}
          {formatMessage(GroupNamesDefaultMessage[groupName], { amount: 0 })}
        </Title>

        <div className={styles.groupLimits}>
          <Input
            type="number"
            value={limit}
            disabled={!targetService}
            min={0}
            onChange={(event) =>
              dispatch(
                productEditorModel.actions.setGroupServiceLimits({
                  groupName,
                  value: +event.target.value,
                }),
              )
            }
            placeholder={formatMessage({
              id: 'productEditor.editor.services.enterLimits',
              defaultMessage: 'Enter Limits',
            })}
          />
        </div>
      </div>

      <div className={styles.serviceList}>
        {services.map((service) => (
          <div>
            <Checkbox
              checked={isServiceChecked({
                product: productForUpdate,
                serviceType: service,
                groupName,
              })}
              onChange={(event) => onServiceChange(event, service)}
            >
              {formatMessage(ServiceNamesDefaultMessage[service], {
                amount: 0,
              })}
            </Checkbox>
          </div>
        ))}
      </div>
    </Col>
  );
};
