// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BillingHistory-module__firstCell--5MWyRC\\+J{padding-left:12px}\n\n[dir=\"rtl\"] .BillingHistory-module__firstCell--5MWyRC\\+J{padding-left:0;padding-right:12px}", "",{"version":3,"sources":["webpack://./src/widgets/billingHistory/ui/billingHistory/BillingHistory.module.scss"],"names":[],"mappings":"AAAA,6CACE,iBAAA;;AADF,yDACE,cAAA,CAAA,kBAAA","sourcesContent":[".firstCell {\n  padding-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"firstCell": "BillingHistory-module__firstCell--5MWyRC+J"
};
export default ___CSS_LOADER_EXPORT___;
