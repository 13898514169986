export const PATHS = {
  main: '/',
  signUp: '/sign-up',
  signIn: '/sign-in',
  user: '/user/:userID',
  organization: '/user/:userID/organization/:organizationID',
  addSubscription: '/user/:userID/organization/:organizationID/add-subscription',
  addPackage: '/user/:userID/organization/:organizationID/add-package',
  productEditor: '/editor',
};
