import { useIntl } from 'react-intl';
import { Breadcrumb, Button, Col, List, Row, Space } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import React, { useState } from 'react';

import { Layout } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { PATHS } from '@/shared/config';
import { userModel } from '@/entities/user';
import { Account } from '@/widgets/account';

import styles from './User.module.scss';
import { CreateOrganizationModal } from './ui/CreateOrganizationModal/CreateOrganizationModal';

export const User = () => {
  const { userID } = useParams<{ userID: string }>();

  const { formatMessage } = useIntl();

  const [isCreateOrganizationModalOpen, setIsCreateOrganizationModalOpen] =
    useState<boolean>(false);

  const currentUserOrganizations = useAppSelector(
    userModel.selectors.selectCurrentUserOrganizations,
  );
  const sharedFromOrganizations = useAppSelector(
    userModel.selectors.selectAllSharedFromOrganizations,
  );
  const currentUser = useAppSelector(userModel.selectors.selectCurrentUser);

  const openCreateOrganizationModal = () =>
    setIsCreateOrganizationModalOpen(true);
  const closeCreateOrganizationModal = () =>
    setIsCreateOrganizationModalOpen(false);

  return (
    <Layout.Content>
      <Breadcrumb
        style={{ padding: '24px 0' }}
        className="p2"
        items={[
          {
            title: (
              <Link className={styles.breadcrumb} to={generatePath(PATHS.main)}>
                {formatMessage({
                  id: 'breadcrumb.home',
                  defaultMessage: 'Home',
                })}
              </Link>
            ),
          },
          {
            title: `${currentUser?.PersonalData?.FirstName ?? ''} ${
              currentUser?.PersonalData?.LastName ?? ''
            }`,
          },
        ]}
      />

      <Row gutter={16} style={{ rowGap: 24 }}>
        <Col md={16} lg={8}>
          <Space direction="vertical" size="large" className={styles.space}>
            <Account userID={userID} />
          </Space>
        </Col>

        <Col md={32} lg={16} style={{ gap: 24 }}>
          <Space direction="vertical" size="small" className={styles.space}>
            {/* TODO: replace via table with subscription name and status */}
            <List
              size="small"
              header={
                <div>
                  {formatMessage({
                    id: 'user.organizationsListTitle',
                    defaultMessage: 'Organizations',
                  })}
                </div>
              }
              bordered
              dataSource={currentUserOrganizations}
              renderItem={(org) => (
                <List.Item>
                  <Link
                    className={styles.organizationLink}
                    to={generatePath(PATHS.organization, {
                      userID: currentUser?.ID,
                      organizationID: org.ID,
                    })}
                  >
                    {org.Name || org.ID}
                  </Link>
                </List.Item>
              )}
            />

            <Button type="primary" onClick={openCreateOrganizationModal}>
              {formatMessage({
                id: 'organization.button.addOrganization',
                defaultMessage: 'Add organization',
              })}
            </Button>

            <List
              size="small"
              loading={!currentUser}
              header={
                <div>
                  {formatMessage({
                    id: 'user.sharedFromOrganizationsListTitle',
                    defaultMessage: 'Shared from',
                  })}
                </div>
              }
              bordered
              dataSource={sharedFromOrganizations}
              renderItem={(org) => (
                <List.Item>
                  <Link
                    className={styles.organizationLink}
                    to={generatePath(PATHS.organization, {
                      userID: currentUser?.ID ?? '',
                      organizationID: org.OrganizationID,
                    })}
                  >
                    {org.OrganizationName || org.OrganizationID}
                  </Link>
                </List.Item>
              )}
            />
          </Space>
        </Col>
      </Row>

      <CreateOrganizationModal
        isOpen={isCreateOrganizationModalOpen}
        onCloseHandler={closeCreateOrganizationModal}
      />
    </Layout.Content>
  );
};
