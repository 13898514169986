import { defineMessages } from 'react-intl';

import { OrderStatus } from '../lib';

export const orderStatusText = defineMessages<OrderStatus>({
  'paid': {
    id: 'orderStatus.paid',
    defaultMessage: 'Paid',
  },
  'new': {
    id: 'orderStatus.new',
    defaultMessage: 'New',
  },
  'overdue': {
    id: 'orderStatus.overdue',
    defaultMessage: 'Overdue',
  },
});
