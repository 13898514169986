import React, { FC, useMemo } from 'react';
import { Checkbox, Col, Input } from 'antd';
import { useIntl } from 'react-intl';

import {
  ALL_SERVICES,
  SERVICE_ENABLE_ONLY,
} from '@/entities/productEditor/config';
import { ServiceNamesDefaultMessage } from '@/shared/config/i18n';
import {
  ServiceGroupName,
  ServiceType,
} from 'protocol/api/billing_new/dto_services_new_pb';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { productEditorModel } from '@/entities/productEditor';

import styles from './Services.module.scss';

type ServicesProps = {
  className?: string;
  testID?: string;
};

const Service = (props: { service: ServiceType }) => {
  const { service } = props;

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const productForUpdate = useAppSelector(
    productEditorModel.selectors.selectProductForUpdate,
  );

  const targetService = productForUpdate?.Services?.find((productService) =>
    productService.Types.every(
      (type) =>
        type === service &&
        productService.Group === ServiceGroupName.ServiceGroupNameEmpty,
    ),
  );

  const limit = Number(
    targetService &&
      targetService.Kind.case === 'Countable' &&
      targetService.Kind.value.Limit,
  );

  const isServiceIncludedInProduct = useMemo(
    () =>
      productForUpdate?.Services?.find((productService) =>
        productService.Types.every(
          (type) =>
            type === service &&
            productService.Group === ServiceGroupName.ServiceGroupNameEmpty,
        ),
      ),
    [service, productForUpdate],
  );

  return (
    <div className={styles.serviceContainer}>
      <Checkbox
        checked={!!isServiceIncludedInProduct}
        style={{ alignItems: 'center' }}
        onChange={(event) => {
          if (event.target.checked) {
            dispatch(productEditorModel.actions.setService(service));
          } else {
            dispatch(productEditorModel.actions.removeService(service));
          }
        }}
      >
        {formatMessage(ServiceNamesDefaultMessage[service], {
          amount: 0,
        })}
      </Checkbox>

      {!SERVICE_ENABLE_ONLY.includes(service) && (
        <Input
          style={{ maxWidth: '150px' }}
          disabled={!isServiceIncludedInProduct}
          type="number"
          value={limit}
          min={0}
          onChange={(event) =>
            dispatch(
              productEditorModel.actions.setServiceLimits({
                type: service,
                limit: +event.target.value,
              }),
            )
          }
          placeholder={formatMessage({
            id: 'productEditor.editor.services.enterLimits',
            defaultMessage: 'Enter Limits',
          })}
        />
      )}
    </div>
  );
};

export const Services: FC<ServicesProps> = () => (
  <Col
    className={styles.servicesList}
    md={16}
    lg={16}
    style={{ marginBottom: 16 }}
  >
    {ALL_SERVICES.map((service) => (
      <Service service={service} key={service} />
    ))}
  </Col>
);
