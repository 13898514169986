import { AnyAction, combineReducers, configureStore, DevToolsEnhancerOptions } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { ENVIRONMENT, SliceName } from '@/shared/config';
// Legacy for client
import authReducer from '@/entities/auth/model/authSlice';
import userReducer from '@/entities/user/model/userSlice';
import billingReducer from '@/entities/billing/model/billingSlice';
import usersReducer from '@/entities/users/model/usersSlice';
import organizationReducer from '@/entities/organization/model/organizationSlice';
import productEditorReducer from '@/entities/productEditor/model/productEditorSlice';

import { errorMiddleware } from './errorMiddleware';

const appReducer = combineReducers({
  [SliceName.auth]: authReducer,
  [SliceName.user]: userReducer,
  [SliceName.billing]: billingReducer,
  [SliceName.users]: usersReducer,
  [SliceName.organization]: organizationReducer,
  [SliceName.productEditor]: productEditorReducer,
});

const rootReducer = (
  state: ReturnType<typeof appReducer>,
  action: AnyAction,
) => {
  if (action.type === 'store/reset') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const devToolsConfig: DevToolsEnhancerOptions = {
  maxAge: 10,
};

export const store = configureStore({
  devTools: ENVIRONMENT !== 'production' && devToolsConfig,
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(errorMiddleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
