import React, { FC } from 'react';
import { Outlet } from 'react-router';
import { useParams } from 'react-router-dom';

import { Header } from '@/widgets/header';
import { Footer } from '@/widgets/footer';
import { Layout } from '@/shared/ui';

import { useAdminUserInfoStream } from './hooks/useAdminUserInfoStream';

export const UserContainer: FC = () => {
  const { userID } = useParams<{ userID: string }>();

  useAdminUserInfoStream(userID);

  return (
    <Layout>
      <Header />
      <Outlet />
      <Footer />
    </Layout>
  );
};
